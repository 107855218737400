/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 30/08/2023.
 */
import React from 'react'
import { Button } from 'antd'
import { CheckOutlined } from '@ant-design/icons'

type Props = { selected: boolean; onClick?: () => void; children?: React.ReactNode; className?: string }

export const SelectedBtn = ({ selected, onClick, children = <p>btbLabel</p>, className = '' }: Props) => {
  const clickker = () => {
    if (onClick) {
      onClick()
    }
  }

  return selected ? (
    <Button
      type={selected ? 'primary' : 'default'}
      onClick={clickker}
      className={className}
      style={{ boxShadow: '0px 0px 0px 3px rgba(0, 0, 0, 0.1)' }}
    >
      <p className="">
        <span className="me-2">{children}</span>
        <CheckOutlined />
      </p>
    </Button>
  ) : (
    <Button onClick={clickker} className={className}>
      {children}
    </Button>
  )
}
