/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 13/03/2023.
 */
import React, { Suspense } from 'react'
import { Link, NavLink, Outlet, useParams } from 'react-router-dom'
import { COLOR_NOT_SELECTED, COLOR_PRIMARY } from '../../constants'
import { HeaderSelectProject } from './HeaderSelectProject'

const headerHeight = 57

export const Layout = () => {
  const { projectId } = useParams() as { projectId: string }

  return (
    <>
      <div className="container-fluid border-bottom bg-white" style={{ boxShadow: '2px 0px 10px rgba(0, 0, 0, 0.04)' }}>
        <div className="row" style={{}}>
          <div className="col-4 d-flex align-items-center">
            <Link
              to="/"
              className="me-2 text-decoration-none d-flex align-items-center"
              style={{ height: headerHeight }}
            >
              <h5 className="fw-bold ms-1 mt-1 text-black">Evaluator</h5>
            </Link>
          </div>
          <div className="col-4 d-flex justify-content-center align-items-center p-small ">
            <NavLink to="/" className="mx-2 text-decoration-none">
              {({ isActive }) => {
                return <p style={{ color: isActive ? COLOR_PRIMARY : COLOR_NOT_SELECTED }}>Projects</p>
              }}
            </NavLink>
            {projectId ? (
              <NavLink to={`/project/${projectId}/subjects`} end className="mx-2 text-decoration-none">
                {({ isActive }) => {
                  return <p style={{ color: isActive ? COLOR_PRIMARY : COLOR_NOT_SELECTED }}>Subjects</p>
                }}
              </NavLink>
            ) : null}
          </div>
          <div className="col-4 d-flex align-items-center justify-content-end">
            <Suspense fallback={'...'}>
              <HeaderSelectProject></HeaderSelectProject>'
            </Suspense>
          </div>
        </div>
      </div>

      <Outlet />
    </>
  )
}
