import React from 'react'

import LoginForm from './LoginForm'

import { Route, Routes } from 'react-router-dom'
import { LOGIN_FORGOT_PASSWORD, LOGIN_FORGOT_PASSWORD_SUBMIT, LOGIN_MFA_CODE, LOGIN_NEW_PASSWORD } from './PAGES_LOGIN'
import { ForgotPasswordSubmit } from './ForgotPasswordSubmit'
import { LoginMFA } from './LoginMFA'
import { ForgotPassword } from './ForgotPassword'
import { CompleteNewPassword } from './CompleteNewPassword'
import { LoginBackground } from './LoginBackground'

export const LoginPage = () => {
  return (
    <div className="container-fluid h-100 bg-secondary">
      <div className="container h-100 ">
        <div className="row h-100" style={{}}>
          <div className="col-12 d-flex flex-column justify-content-center align-items-center">
            <Routes>
              <Route
                path="/"
                element={
                  <LoginBackground>
                    <LoginForm></LoginForm>
                  </LoginBackground>
                }
              ></Route>

              <Route
                path={LOGIN_MFA_CODE.path}
                element={
                  <LoginBackground>
                    <LoginMFA />
                  </LoginBackground>
                }
              />

              <Route
                path={LOGIN_FORGOT_PASSWORD.path}
                element={
                  <LoginBackground>
                    <ForgotPassword />
                  </LoginBackground>
                }
              />
              <Route
                path={LOGIN_FORGOT_PASSWORD_SUBMIT.path}
                element={
                  <LoginBackground>
                    <ForgotPasswordSubmit />
                  </LoginBackground>
                }
              />
              <Route
                path={LOGIN_NEW_PASSWORD.path}
                element={
                  <LoginBackground>
                    <CompleteNewPassword />
                  </LoginBackground>
                }
              />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  )
}
