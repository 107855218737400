/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 28/08/2023.
 */
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useSubjects } from '../../hooks/useSubjects'
import { Button } from 'antd'
import { useMeProjects } from '../../hooks/useMeProjects'

export const ProjectView = () => {
  const { projectId } = useParams() as { projectId: string }

  const { data } = useSubjects(projectId)
  const { data: projectList } = useMeProjects()

  const selectedProjectArr = projectList?.projects.filter((item) => {
    return item._id === projectId
  })

  console.log('ProjectView > data = ', data)
  console.log('ProjectView > projectList = ', projectList)
  console.log('ProjectView > selectedProjectArr = ', selectedProjectArr)

  const selectedProject =
    selectedProjectArr && selectedProjectArr.length > 0 ? selectedProjectArr[0] : { title: 'no projects for this user' }

  return (
    <div className="container">
      <div className="row" style={{}}>
        <div className="col-12 my-4 d-flex">
          <p className="opacity-75 me-1">Project: </p>
          <p className="opacity-75 fw-bold">{selectedProject.title} </p>
        </div>
      </div>
      <div className="row" style={{}}>
        {data.map((subject, index) => {
          return (
            <div key={index} className="col-12 mb-2">
              <div className="border bg-white p-4 w-100 d-flex justify-content-between align-items-center">
                <p className="fw-bold">{subject.displayName}</p>
                <Link to={`${subject._id}`} className="">
                  <Button type="primary">Evaluate subject</Button>
                </Link>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
