/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 16/08/2023.
 */

import { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Layout } from './components/display/Layout'

import { SpinnerFullHeight } from './components/spinner/SpinnerFullHeight'
import { ProjectList } from './pages/project/ProjectList'
import { ProjectView } from './pages/project/ProjectView'
import { ProjectEvaluateSubject } from './pages/project/ProjectEvaluateSubject'

export const Main = () => {
  return (
    <>
      <Routes>
        <Route path={'/'} element={<Layout />}>
          <Route
            path={'/'}
            element={
              <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                <ProjectList />
              </Suspense>
            }
          />
          <Route
            path={'/project/:projectId/*'}
            element={
              <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                <ProjectContainer></ProjectContainer>
              </Suspense>
            }
          />
        </Route>

        <Route path={'*'} element={<Navigate replace to="/" />} />
      </Routes>
    </>
  )
}

const ProjectContainer = () => {
  return (
    <Routes>
      <Route
        path={'/subjects'}
        element={
          <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
            <ProjectView></ProjectView>
          </Suspense>
        }
      />

      <Route
        path={'/subjects/:subjectId'}
        element={
          <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
            <ProjectEvaluateSubject></ProjectEvaluateSubject>
          </Suspense>
        }
      />
      {/*<Route
        path={SITE_LIST_PAGE.path}
        element={
          <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
            <h1>asdsadas</h1>
          </Suspense>
        }
      />*/}
    </Routes>
  )
}
