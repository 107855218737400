/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 28/08/2023.
 */
import React from 'react'

import Lightbox from 'yet-another-react-lightbox'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import 'yet-another-react-lightbox/styles.css'

type Props = { slides: any[]; visible: boolean; setVisible: (value: boolean) => void }

export const ImageLightbox = ({ visible, setVisible, slides }: Props) => {
  //  const [advancedExampleOpen, setAdvancedExampleOpen] = React.useState(true)

  return (
    <>
      <Lightbox
        open={visible}
        close={() => setVisible(false)}
        slides={slides}
        plugins={[/*Captions, Fullscreen, Slideshow, Thumbnails, Video*/ Zoom]}
      />
    </>
  )
}
