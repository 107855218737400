/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 29/08/2023.
 */
import React, { useState } from 'react'
import { Alert, Button, Input, Select } from 'antd'

import { SelectedBtn } from '../../components/SelectedBtn'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { post } from '../../service/API'
import { useNavigate, useParams } from 'react-router-dom'

const { TextArea } = Input
type Props = {
  answerId: string
}

const acceptReasons = [
  { value: 0, label: 'Image blurred' },
  { value: 1, label: 'Recommend retake' },
  { value: 2, label: 'Image does not show condition' },
  { value: 3, label: 'Image does not show condition' },
  { value: 4, label: 'Image does not show condition' },
  { value: 5, label: 'Image does not show condition' },
  { value: 6, label: 'Image does not show condition' },
  { value: 7, label: 'Image does not show condition' },
  { value: 8, label: 'Image does not show condition' },
  { value: 9, label: 'Image does not show condition' },
  { value: 10, label: 'Image does not show condition' },
]

const declineReasons = [
  { value: 0, label: 'Image blurred' },
  { value: 1, label: 'Image does not show condition' },
  { value: 2, label: 'low image quality' },
]

const ImageEvaluationState = {
  IMAGE_ACCEPTED: 'IMAGE_ACCEPTED',
  IMAGE_NOT_ACCEPTED: 'IMAGE_NOT_ACCEPTED',
} as const

type ImageEvaluationStateType = (typeof ImageEvaluationState)[keyof typeof ImageEvaluationState]

export const EvaluationForm = ({ answerId }: Props) => {
  const { subjectId, projectId } = useParams() as { subjectId: string; projectId: string }
  const navigate = useNavigate()
  console.log('EvaluationForm > answerId = ', answerId)
  const [imageAccepted, setImageAccepted] = React.useState<ImageEvaluationStateType | null>(null)
  const [error, setError] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const imageAcceptedTrue = () => {
    setError('')
    setImageAccepted(ImageEvaluationState.IMAGE_ACCEPTED)
  }
  const imageAcceptedFalse = () => {
    setError('')
    setImageAccepted(ImageEvaluationState.IMAGE_NOT_ACCEPTED)
  }

  const saveEvaluation = (data) => {
    if (loading) {
      return
    }

    if (imageAccepted === ImageEvaluationState.IMAGE_NOT_ACCEPTED && !data.declined) {
      setError("Please select a reason why the image can't be used")
      return
    }

    if (imageAccepted === ImageEvaluationState.IMAGE_ACCEPTED && data.score === null) {
      setError('Please select a score for the image')
      return
    }

    setError('')
    const obj = { ...data, status: imageAccepted, enrollmentId: subjectId, answerId: answerId }

    console.log('EvaluationForm > obj = ', obj)

    setLoading(true)
    const p = ENDPOINTS.EVALUATION.POST()
    post(p, obj)
      .then((result) => {
        console.log('EvaluationForm > result = ', result)
        setLoading(false)

        navigate('/project/' + projectId + '/subjects')
        return result
      })
      .catch((error) => {
        setLoading(false)
        console.log('EvaluationForm > error = ', error)
        return Promise.reject(error)
      })
  }

  return (
    <>
      <div className=" bg-white p-4" style={{ boxShadow: '0px 2px 10px -2px rgba(0, 0, 0, 0.1)' }}>
        <div className="mb-4">
          <h5>Evaluate Image</h5>
          <p className="mb-3 ">Can this image be used for evaluation</p>
          {/*<p className="mb-3">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium ad atque cumque eaque earum
          </p>*/}

          <SelectedBtn
            onClick={imageAcceptedTrue}
            className="me-2"
            selected={imageAccepted === ImageEvaluationState.IMAGE_ACCEPTED}
          >
            Yes
          </SelectedBtn>

          <SelectedBtn
            onClick={imageAcceptedFalse}
            selected={imageAccepted === ImageEvaluationState.IMAGE_NOT_ACCEPTED}
          >
            No
          </SelectedBtn>
        </div>

        {imageAccepted === ImageEvaluationState.IMAGE_ACCEPTED ? (
          <AcceptedImage loading={loading} error={error} saveEvaluation={saveEvaluation}></AcceptedImage>
        ) : null}
        {imageAccepted === ImageEvaluationState.IMAGE_NOT_ACCEPTED ? (
          <DeclinedImage error={error} loading={loading} saveEvaluation={saveEvaluation}></DeclinedImage>
        ) : null}
      </div>
    </>
  )
}

const AcceptedImage = ({ saveEvaluation, loading, error }) => {
  const [selected, setSelected] = React.useState<number | null>(null)
  const [comment, setComment] = React.useState<string>('')

  const handleChange = (index) => {
    setSelected(index)
  }

  const saveClick = () => {
    const o = { comment: comment, score: selected }
    console.log('EvaluationForm > o = ', o)
    saveEvaluation(o)
  }

  return (
    <div className="">
      <hr />
      <h5 className="mb-2 mt-4">Please evaluate the image </h5>

      <p className="mb-3">Based on the images, how likely is the user to be eligble for this study</p>
      <div className="d-flex">
        {acceptReasons.map((item, index) => {
          const isSelected = selected === index
          return (
            <div key={index} className="">
              <SelectedBtn
                className="me-1"
                selected={isSelected}
                onClick={() => {
                  handleChange(index)
                }}
              >
                {item.value}
              </SelectedBtn>
            </div>
          )
        })}
      </div>
      <hr />
      <CommentField value={comment} setValue={setComment}></CommentField>
      <hr />
      <div className="mt-3">
        {error ? (
          <Alert className="mb-3" message="Something went wrong" description={error} type="error" closable />
        ) : null}

        <Button loading={loading} onClick={saveClick} type="primary">
          Save evaluation
        </Button>
      </div>
    </div>
  )
}

const DeclinedImage = ({ saveEvaluation, loading, error }) => {
  const [state, setState] = React.useState<any>(null)
  const [comment, setComment] = React.useState<string>('')
  const handleChange = (e, k) => {
    const reason: string = declineReasons[e]?.label
    setState(reason)
  }

  const saveClick = () => {
    const o = { comment: comment, declined: state }
    saveEvaluation(o)
  }

  return (
    <div className="">
      <hr />

      <h5 className="mb-2 mt-4">Please select a reason to why the image can not be used</h5>

      <p className="mb-3">
        If the image can not be used for an evaluation. The recruitment team will determin if the user needs to be
        prompted to do a image retake.
      </p>
      <div className="mb-2 ">
        <Select
          popupMatchSelectWidth={false}
          className="w-75"
          style={{ minWidth: 250 }}
          value={state}
          onChange={handleChange}
          placeholder={'--'}
          options={declineReasons}
        ></Select>
      </div>
      <hr />
      <CommentField value={comment} setValue={setComment}></CommentField>
      <hr />
      {error ? (
        <Alert className="mb-3" message="Something went wrong" description={error} type="error" closable />
      ) : null}
      <Button loading={loading} type="primary" onClick={saveClick}>
        Save evaluation
      </Button>
    </div>
  )
}

const CommentField = ({ value, setValue }) => {
  return (
    <div className="">
      <h5 className="mb-3 mt-4 ">
        Please add a comment <span className="p-small opacity-50">(optional)</span>
      </h5>
      <TextArea
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder="Your comments in regards to this evaluation"
        autoSize={{ minRows: 3, maxRows: 5 }}
      />
    </div>
  )
}
