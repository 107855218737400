import React, { useEffect, useState } from 'react'

import { authStore } from './store/auth/authStore'
import { SpinnerFullHeight } from './components/spinner/SpinnerFullHeight'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorView } from './components/error/ErrorView'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { LoginPage } from './pages/login/LoginPage'
import { Main } from './Main'

import { DebugGrid } from './components/debug/DebugGrid'
import { COLOR_PRIMARY } from './constants'
import { ConfigProvider } from 'antd'

function App() {
  const user: any = authStore((state) => state.user)
  const init = authStore((state) => state.init)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (!user) {
      init().catch((error: any) => {
        console.log(' App > init = ', error)
        setError(error)
      })
    }
  }, [init, user])

  if (!user && !error) {
    return <SpinnerFullHeight></SpinnerFullHeight>
  }

  const shouldRedirect = !user || user.challengeName

  return (
    <>
      {process.env.NODE_ENV !== 'production' ? <DebugGrid /> : null}
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: COLOR_PRIMARY,
          },
        }}
      >
        <BrowserRouter>
          <ErrorBoundary FallbackComponent={ErrorView}>
            <Routes>
              <Route path="/login/*" element={<LoginPage />} />

              <Route path="/*" element={shouldRedirect ? <Navigate replace to="/login" /> : <Main />} />
            </Routes>
          </ErrorBoundary>
        </BrowserRouter>
      </ConfigProvider>
    </>
  )
}

export default App
