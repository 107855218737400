/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 17/05/2021.
 */
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { authStore } from '../../store/auth/authStore'
import './login-form.scss'
import { LOGIN_FORGOT_PASSWORD, LOGIN_MFA_CODE, LOGIN_NEW_PASSWORD } from './PAGES_LOGIN'

const LoginForm = () => {
  const navigate = useNavigate()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const login = authStore((state) => state.login)
  const [error, setError] = useState('')

  const handleFormSubmit = (event) => {
    event.preventDefault()

    login(email, password)
      .then((res: any) => {
        console.log(' LoginForm > res = ', res)
        console.log(' LoginForm > res.challengeName = ', res.challengeName)

        if (!res.challengeName) {
          navigate('/', { replace: true })
        }
        if (res.challengeName === 'NEW_PASSWORD_REQUIRED') {
          navigate(LOGIN_NEW_PASSWORD.to, { replace: true })
          return
        }
        if (res.challengeName === 'SMS_MFA') {
          navigate(LOGIN_MFA_CODE.to, { replace: true })
          return
        }
      })
      .catch((error) => {
        console.log(' LoginForm > error = ', error)
        setError(error.message)
      })
  }

  return (
    <>
      <h3 className="font-weight-bold text-center">Sign in</h3>
      <form className="login-page w-100" onSubmit={handleFormSubmit}>
        <div className="w-100 ">
          <label htmlFor="username">E-mail</label>
          <input
            id="username"
            type="text"
            placeholder="Username"
            autoComplete="username"
            value={email}
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            style={{ borderRadius: 4, height: 48 }}
          />
        </div>
        <div className="w-100">
          <label htmlFor="password">Password</label>
          <input
            id="password"
            type="password"
            autoComplete="current-password"
            placeholder="Password"
            value={password}
            name="password"
            onChange={(e) => setPassword(e.target.value)}
            style={{ borderRadius: 4, height: 48 }}
          />
          <div className=" ">
            <input
              style={{ background: 'black', cursor: 'pointer', color: 'white', borderRadius: 4, height: 48 }}
              type="submit"
              value="Login"
            />
            <p className="mt-0 text-center text-danger">{error}</p>
          </div>
        </div>
        <Link to={LOGIN_FORGOT_PASSWORD.to} className="d-flex justify-content-center ">
          <p className="p-small">Forgot password</p>
        </Link>
      </form>
    </>
  )
}
export default LoginForm
