import { BASE_URL } from '../constants'

export const ENDPOINTS = {
  PROJECTS: {
    GET: () => {
      return BASE_URL + '/evaluator/me'
    },

    GET_PROJECT_BY_ID: (projectId) => {
      return BASE_URL + `/evaluator/projects/${projectId}`
    },
  },
  SUBJECTS: {
    getByProjectId: (projectId) => {
      return BASE_URL + `/evaluator/projects/${projectId}/subjects`
    },
    getSubject: (subjectId, projectId) => {
      return BASE_URL + `/evaluator/projects/${projectId}/subjects/${subjectId}`
    },
  },
  IMAGE: {
    GET: (fileName: string, uid: string, projectId: string) => {
      return BASE_URL + `/evaluator/projects/${projectId}/image/${fileName}/${uid}`
    },
  },
  EVALUATION: {
    POST: () => {
      return BASE_URL + `/evaluator/evaluation`
    },
  },
} as const

/*

//
// extracted to be able to mock and validated type
//
const getUserType = () => {
  const userType = meStore.getState().me?.type.toLowerCase()
  if (!userType) {
    throw Error('User type is not defined in meStore.')
  }

  return 'ctm'
}
*/
