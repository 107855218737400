/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 21/04/2023.
 */
import React from 'react'
//import { ReactComponent as Logo } from '../../assets/logo.svg'

type Props = { children: React.ReactElement }

export const LoginBackground = ({ children }: Props) => {
  return (
    <>
      <div
        className="bg-white p-4  w-100 rounded-1"
        style={{ maxWidth: 375, boxShadow: '0px 6px 40px -4px rgba(0, 0, 0, .3)' }}
      >
        {children}
      </div>
    </>
  )
}
