import { LanguageISOCode, QuestionType } from './Types'

export const DEFAULT_LANG: LanguageISOCode = 'en'

// Portal

export const BASE_URL = process.env.REACT_APP_BASE_URL

export const DEBUG = process.env.REACT_APP_DEBUG === 'true' ? true : false

export const REACT_APP_ENV = process.env.REACT_APP_ENV

export const COLOR_DANGER = '#dc3545'
export const COLOR_PLACEHOLDER = '#eaeaea'
export const COLOR_PRIMARY = '#0d6efd' //'#007bff'
export const COLOR_NOT_SELECTED = '#777777'

export const MULTIPLE_CHOICE = 'MULTIPLE_CHOICE'
export const MULTIPLE_RESPONSE = 'MULTIPLE_RESPONSE'
export const TEXT_FREE = 'TEXT_FREE'
export const INFORMATION = 'INFORMATION'

export const QUESTION_TYPES: QuestionType[] = [
  'text',
  'checkbox',
  'radio',
  'number',
  'info',
  'bmi',
  'phonenumber',
  'name',
  'gender',
  'birthdate',
]
