/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 28/08/2023.
 */
import React, { Suspense, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSubject } from '../../hooks/useSubject'
import { ImageLightbox } from '../../components/lightbox/ImageLightbox'
import { useImage } from '../../hooks/useImage'
import { getReq } from '../../service/API'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { EvaluationForm } from './EvaluationForm'
import { CheckOutlined } from '@ant-design/icons'

export const ProjectEvaluateSubject = () => {
  const { projectId, subjectId } = useParams() as { projectId: string; subjectId: string }
  const [showlightBox, setShowlightBox] = useState<boolean>(false)

  const { data } = useSubject(subjectId, projectId)
  const [urlLoaded, setUrlLoaded] = useState<{ src: string }[]>([])
  const imageArr = data?.imageAnswer.map((item) => {
    return JSON.parse(item.value)
  })

  const answerId = data?.imageAnswer.length === 1 ? data?.imageAnswer[0]._id : null

  const showLightboxClick = () => {
    setShowlightBox(true)
  }

  useEffect(() => {
    const que = data?.imageAnswer
      .map((item) => {
        return JSON.parse(item.value)
      })
      .map((item) => {
        return getReq(ENDPOINTS.IMAGE.GET(item.fileName, item.fileUid, projectId))
      })
    Promise.all(que)
      .then((result) => {
        const urlArr = result.map((item) => {
          return { src: item.data }
        })
        setUrlLoaded(urlArr)
      })
      .catch((error) => {
        console.log('ProjectEvaluateSubject > error = ', error)
        return Promise.reject(error)
      })
  }, [subjectId, data?.imageAnswer, projectId])

  return (
    <>
      <ImageLightbox visible={showlightBox} setVisible={setShowlightBox} slides={urlLoaded}></ImageLightbox>
      <div className="container-fluid">
        <div className="row" style={{ background: '#dadada' }}>
          <div className="col-12 mt-3 mb-2 d-flex justify-content-center">
            {imageArr?.map((item, index) => {
              return (
                <div key={index} className="d-flex flex-column " style={{ minWidth: 250 }}>
                  <Suspense fallback="loading">
                    <ImageWrapper
                      key={index}
                      showLightboxClick={showLightboxClick}
                      fileName={item.fileName}
                      uid={item.fileUid}
                    ></ImageWrapper>
                  </Suspense>
                  <p className="p-small text-center mt-2">Click to enlarge</p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row" style={{}}>
          <div className="col-12 mt-4"></div>
        </div>
        <div className="row " style={{}}>
          <div className="col-4 ">
            <div className="bg-white px-3 py-3 h-100" style={{ boxShadow: '0px 2px 10px -2px rgba(0, 0, 0, 0.1)' }}>
              <p className="mb-3 fw-bold">Question and answers</p>
              {data.answers.map((answer, index) => {
                return (
                  <div key={index} className="col-12 d-flex">
                    <div className="me-3 position-relative ">
                      <div className="rounded-circle bg-primary mt-5 " style={{ height: 10, width: 10 }}></div>
                      <div
                        className="bg-primary h-100 ms-1 position-absolute"
                        style={{ top: 0, left: 0, width: 1 }}
                      ></div>
                    </div>
                    <QuestionContent answer={answer} index={index}></QuestionContent>
                  </div>
                )
              })}

              <div className="col-12 d-flex">
                <div className="me-3 position-relative ">
                  <div className="rounded-circle bg-primary mt-5" style={{ height: 10, width: 10 }}></div>
                  <div
                    className="bg-primary h-100 ms-1 mt-2 position-absolute"
                    style={{ top: -25, left: 0, width: 1 }}
                  ></div>
                </div>
                <p className="mt-4 pt-3">End</p>
              </div>
            </div>
          </div>

          <div className="col-8">
            <EvaluationForm answerId={answerId}></EvaluationForm>
          </div>
        </div>
      </div>
    </>
  )
}

const QuestionContent = ({ answer, index }) => {
  const lang = answer.language

  const qTitle = answer?.questionId?.title[lang]
  const qBody = answer?.questionId?.body ? answer?.questionId?.body[lang] : 'no body text'
  const qType = answer?.questionId?.type
  const options = answer?.questionId?.options

  return (
    <div className="" style={{ marginTop: 36 }}>
      <div className="d-flex mb-2 position-relative">
        {/*<p className="p-small opacity-75 ">type</p>*/}
        <div className="position-absolute bg-primary" style={{ top: 16, left: -17, height: 1, width: 20 }}></div>
        <p className="rounded-2 border bg-primary text-white  p-small ps-2 pe-3 py-1">
          <span className="me-2">{index + 1}.</span>
          {qType}
        </p>
      </div>
      <div className="mb-2">
        <p className="p-small opacity-75 ">Title</p>
        <p className="">{qTitle}</p>
      </div>
      <div className="mb-2">
        <p className="p-small opacity-75 ">Body</p>
        <p className="">{qBody}</p>
      </div>

      {parseAnswerType(qType, options, answer?.value, lang)}
    </div>
  )
}

const parseAnswerType = (type, options, value, lang) => {
  if (type === 'text') {
    return <AnswerOption selected={true}>{value}</AnswerOption>
  }

  if (type === 'phonenumber') {
    return <AnswerOption selected={false}>{value}</AnswerOption>
  }

  if (type === 'checkbox') {
    const selectedIndex = parseInt(value)
    return (
      <div className="d-flex mt-2">
        {options?.map((item, index) => {
          const isSelected = selectedIndex === index

          const label = item[lang] || 'no label'
          return (
            <AnswerOption key={index} selected={isSelected}>
              {label}
            </AnswerOption>
          )
        })}
      </div>
    )
  }

  return <p>Question type missing</p>
}

const ImageWrapper = ({ showLightboxClick, fileName, uid }) => {
  const { projectId } = useParams() as { projectId: string }
  const { data } = useImage(fileName, uid, projectId)

  const bgImage = 'url(' + data + ')'

  return (
    <div
      onClick={showLightboxClick}
      className="bg-primary ratio ratio-1x1 bg-secondary pointer"
      style={{
        boxShadow: '0px 2px 20px -8px rgba(0, 0, 0, 0.5)',
        backgroundImage: bgImage,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    ></div>
  )
}

const AnswerOption = ({ selected, children = <p>label missing</p> }) => {
  return selected ? (
    <div
      className="rounded-2 d-flex align-items-center me-2 bg-secondary text-white px-2 py-1 noselect "
      style={{ boxShadow: '0px 0px 0px 3px rgba(0, 0, 0, 0.1)' }}
    >
      <p className="p-small">
        <span className="me-1">{children}</span>
        <CheckOutlined />
      </p>
    </div>
  ) : (
    <div className="rounded-2 d-flex align-items-center me-2  px-2 py-1 noselect border">
      <p className="p-small">
        <span className=" ">{children}</span>
      </p>
    </div>
  )
}
