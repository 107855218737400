/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 27/08/2023.
 */
import React from 'react'
import { useMeProjects } from '../../hooks/useMeProjects'
import { Button } from 'antd'
import { Link } from 'react-router-dom'

export const ProjectList = () => {
  const { data } = useMeProjects()

  console.log('ProjectList > data = ', data)

  return (
    <div className="container">
      <div className="row" style={{}}>
        <div className="col-12 my-4">
          <p className="p-small fw-bold">Projects</p>
        </div>
      </div>
      <div className="row" style={{}}>
        {data?.projects.length > 0 ? (
          data?.projects.map((project, index) => {
            return (
              <div key={index} className="col-12 ">
                <div
                  className="border py-4 rounded-1 px-3 d-flex justify-content-between bg-white"
                  style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.04)' }}
                >
                  <p className="fw-bold mb-0">{project.title}</p>
                  <Link to={'/project/' + project._id + '/subjects'}>
                    <Button type="primary" className="">
                      Start evaluating
                    </Button>
                  </Link>
                </div>
              </div>
            )
          })
        ) : (
          <h2>You dont have access to any projects</h2>
        )}
      </div>
    </div>
  )
}
