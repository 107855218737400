import { getReq } from '../service/API'
import useSWR from 'swr'
import { ENDPOINTS } from '../service/ENDPOINTS'

export const useImage = (fileName: string, uid: string, projectId: string) => {
  const p = ENDPOINTS.IMAGE.GET(fileName, uid, projectId)

  const { data, error, mutate } = useSWR(p, getReq, { suspense: true })

  return {
    data: !data ? data : data.data,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  }
}
