/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 21/04/2023.
 */

import React, { useState } from 'react'
import { Select } from 'antd'

import { useParams } from 'react-router-dom'
import { useMeProjects } from '../../hooks/useMeProjects'

export const HeaderSelectProject = () => {
  //const { data } = { data: [{ _id: '132121', title: 'Strides' }] }
  const [state] = useState()
  const { projectId } = useParams() as { projectId: string }

  const { data } = useMeProjects()

  const selected = data?.projects
    ? data?.projects.filter((item) => {
        return item._id === projectId
      })
    : []

  const handleChange = (e, k) => {
    //const to = TRIAL_PAGE.goto(e.key)
    const href = window.location.origin
    const newLocation = href + '/project/' + e.toString() + '/'

    window.location.replace(newLocation)
  }

  //
  const items = data
    ? data?.projects.map((usersOnProject) => {
        return { value: usersOnProject._id, label: usersOnProject.title }
      })
    : []

  const value = selected.length === 1 ? selected[0]._id : '---'

  return (
    <div className="w-75 mb-1 d-flex" style={{ minWidth: 150, maxWidth: 200 }}>
      {projectId ? (
        <>
          <p className="p-small me-2 fw-bold" style={{ marginTop: 7 }}>
            Projects:
          </p>
          <Select
            popupMatchSelectWidth={false}
            className="w-auto"
            style={{ minWidth: 150 }}
            defaultValue={value}
            value={state}
            //bordered={false}
            onChange={handleChange}
            placeholder={'--'}
            options={items}
          ></Select>
        </>
      ) : null}
    </div>
  )
}
